<template>
  <div class="popup">
    <div class="popup__bg" @click="$emit('close')"></div>

    <div class="popup__inner">
      <h2 class="popup__heading">
        <slot name="heading"></slot>
      </h2>

      <p class="popup__description">
        <slot name="description"></slot>
      </p>

      <a class="popup__close" @click="$emit('close')">&times;</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupLogin',
  emits: ['close']
}
</script>

<style lang="scss" scoped>
.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  &__bg {
    position: fixed;
    top: 0;
    bottom: -100px;
    left: 0;
    right: 0;
    background: rgba(var(--black-rgb), 0.75);
    cursor: pointer;
  }

  &__inner {
    position: relative;
    max-height: 100%;
    width: 440px;
    max-width: 100%;
    text-align: center;
    padding: 35px 20px 45px 20px;
    border-radius: 10px;
    background: var(--gray-7);
  }

  &__heading {
    font-size: $header-md;
    margin: 0 0 20px 0;
  }

  &__description {
    font-size: $text-lg;
    color: var(--gray-3);
    margin: 0;

    ::v-deep(br) {
      display: none;

       @include phone-portrait {
         display: block;
       }
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    width: auto;
    font-size: 30px;
    color: var(--gray-3);
    padding: 2px 12px;
    border-radius: 10px;

    @include tablet-portrait {
      padding: 5px 16px;
    }
  }
}
</style>