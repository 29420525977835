<template>
  <div class="logo-nest">
    <img :src="vaporAsset('images/icons/email.svg')"  alt="mail" class="logo-nest__img logo-nest__img--email" v-if="isCheckYourEmailView">
    <template v-else>
      <img :src="vaporAsset(`images/icons/${logoImg}`)" alt="logo" class="logo-nest__img">
    </template>
  </div>
</template>

<script>
export default {
  name: 'LogoLogin',
  computed: {
    logoImg () {
      return this.domain === 'pp' ? 'logo-small-pp.svg' : 'logo-small.svg'
    },
    isCheckYourEmailView () {
      return ['check-your-email', 'login-check-email'].includes(this.$route.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-nest {
  &__img {
    display: block;
    height: 45px;
    max-width: 45px;
    width: auto;
    margin: 0 auto;

    @include phone-portrait {
      height: 55px;
      max-width: 55px;
    }
    @include tablet-portrait {
      height: 61px;
      max-width: 61px;
    }

    &--email {
      max-width: none;
    }
  }
}
</style>