import commonPasswords from '@shared/commonPasswords'

// CONSTANTS
const charLimit = 6

export default {
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  computed: {
    errors () {
      return this.$root.errors
    },
    noErrors () {
      return Object.keys(this.errors).every((k) => !this.errors[k].length)
    },
    isMessage() {
      return this.$root.message
    },
    validEmail () {
      const re = /^(?:[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i

      return re.test(this.form.email)
    }
  },
  methods: {
    validateEmail () {
      if (!this.form.email || !this.form.email.length) {
        this.$root.errors.email = [this.$t('forms.pleaseEnterYourEmail')]
      } else if (!this.validEmail) {
        this.$root.errors.email = [this.$t('forms.pleaseEnterAValidEmail')]
      } else {
        delete this.$root.errors.email
      }
    },
    validatePassword () {
      if (!this.form.password || !this.form.password.length) {
        this.$root.errors.password = [this.$t('forms.pleaseEnterYourPassword')]
      } else {
        delete this.$root.errors.password
      }
    },
    validateNewPassword () {
      this.clearErrors()

      const emailName = this.form.email.split('@')[0] || null
      const emailDomain = this.form.email.match(/(?=[^@]*$).*$/)[0].split('.')[0] || null

      if (this.form.password.length < charLimit) {
        this.$root.errors.password = []
        this.$root.errors.password.push(this.$t('forms.passwordMustBeAtLeast6'))
        this.$root.errors.password.push(this.$t('forms.passwordMustNotContain'))
      } else {
        if (this.form.password.includes(emailName) || this.form.password.includes(emailDomain)) {
          this.$root.errors.password = []
          this.$root.errors.password.push(this.$t('forms.passwordMustBeAtLeast6'))
          this.$root.errors.password.push(this.$t('forms.passwordMustNotContain'))
        }
      }
      if (commonPasswords.some(v => this.form.password === v)) {
        this.$root.errors.password = []
        this.$root.errors.password.push(this.$t('forms.passwordYouChose'))
      }
    },
    validateFirstName () {
      if (!this.form.firstName || !this.form.firstName.length) {
        this.$root.errors.firstName = [this.$t('forms.pleaseEnterYourFirstName')]
      } else {
        delete this.$root.errors.firstName
      }
    },
    validateLastName () {
      if (!this.form.lastName || !this.form.lastName.length) {
        this.$root.errors.lastName = [this.$t('forms.pleaseEnterYourLastName')]
      } else {
        delete this.$root.errors.lastName
      }
    },
    validateMessage () {
      if (!this.form.message || !this.form.message.length) {
        this.$root.errors.message = [this.$t('forms.pleaseEnterYourMessage')]
      } else {
        delete this.$root.errors.message
      }
    },
    clearErrors () {
      this.$root.errors = {}
      this.$root.message = null
    }
  }
}
