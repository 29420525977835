<template>
  <div class="form-view login-form">
    <sidebar :backgroundRandom="sidebarLeftImg"></sidebar>

    <div class="form-view__content">
      <div class="form-view__nest">
        <div class="form-view__inner login-form__inner" :class="isFromBlog ? 'login-form__inner--new-user' : ''">
          <div class="form-view__top">
            <logo></logo>
          </div>

          <h1 class="form-view__title">{{ isCamGuru ? 'CamGuru' : $t('welcome') }}</h1>
          <p class="form-view__description" v-html="$t('auth.enterEmailToConfirm')"></p>

          <div class="form-view__form">
            <fieldset class="form-view__fieldset">
              <div class="form-view__input-nest" :class="{'form-view__input-nest--error': errors.email}">
                <input
                  v-model.trim="form.email"
                  type="email"
                  name="email"
                  placeholder=" "
                  id="username"
                  class="form-view__input"
                  :class="{'form-view__input--error': errors.email}"
                  @input="delete errors.email"
                  @keyup.enter="submitForm"
                >
                <label for="username" class="form-view__placeholder" :class="{'form-view__placeholder--error': errors.email}">
                  <template v-if="errors.email">{{ errors.email[0] }}</template>
                  <template v-else>{{ $t('forms.yourEmail') }}</template>
                </label>
              </div>

              <span class="form-view__error-info" v-if="errors.email && canSubmit">
                {{ $t('auth.makeSureSameEmail') }}
              </span>
            </fieldset>

            <button class="form-view__submit" :class="{'form-view__submit--disabled': !canSubmit}" @click="submitForm">
              <span class="form-view__submit-loading" v-if="submitLoading"></span>
              <template v-else>{{ $t('auth.continueWithEmail') }}</template>
            </button>
          </div>

          <div class="form-view__bottom">
            <router-link :to="{ name: 'contact' }" @click="trackNavigationToContact">{{ $t('forms.needHelp') }}</router-link>
          </div>
        </div>
      </div>

      <transition name="deleted">
        <account-deleted @close="hideAccountDeleted = true" v-if="showAccountDeleted" />
      </transition>
      <language-switcher :floating="true" />
    </div>
  </div>
</template>

<script>
import Sidebar from '@login/components/layout/Sidebar.vue'
import Logo from '@login/components/layout/Logo.vue'
import AccountDeleted from '@login/components/ui/AccountDeleted.vue'
import LanguageSwitcher from '@shared/components/ui/LanguageSwitcher.vue'
import loginMixin from '@login/mixins/loginMixin'
import appClient from '@shared/api/appClient'

export default {
  name: 'LoginForm',
  components: {
    Sidebar,
    Logo,
    AccountDeleted,
    LanguageSwitcher
  },
  props: {
    sidebarLeftImg: {
      type: Number,
      required: true
    },
    customerPopupClosed: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      submitLoading: false,
      hideAccountDeleted: false
    }
  },
  computed: {
    isFromBlog () {
      return this.$route.query.from === 'blog'
    },
    canSubmit () {
      return this.form.email.length
        && this.validEmail
    },
    isCamGuru () {
      const currentURL = window.location.search
      const urlParams = new URLSearchParams(currentURL)

      return urlParams.has('camguru')
    },
    showAccountDeleted () {
      const currentURL = window.location.search
      const urlParams = new URLSearchParams(currentURL)

      return urlParams.has('account_deleted') && !this.hideAccountDeleted
    }
  },
  mixins: [loginMixin],
  methods: {
    submitForm () {
      this.validateForm()

      if (!this.noErrors || this.submitLoading) {
        return
      }
      const form = new FormData()

      form.append('email', this.form.email)

      if (this.form.recaptcha_token) {
        form.append('recaptcha_token', this.form.recaptcha_token)
      }

      this.trackEvent('button_clicked', { button: 'add_email_view_continue' })

      this.submitLoading = true

      appClient.loginUser(form).then(() => {
        this.$router.push({ name: 'login-check-email' })
      }).catch(({ response }) => {
        this.submitLoading = false
        this.$root.errors = response.data.errors ? response.data.errors : {}

        if (response) {
          if  (response.status === 404) {
            this.$router.push({ name: 'login-new-user' })
          }
        }

      })
    },
    validateForm () {
      this.validateEmail()
    },
    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
    },
    trackNavigationToContact () {
      this.trackEvent('button_clicked', { button: 'add_email_view_need_help' })
    }
  },
  unmounted () {
    this.clearErrors()
  }
}
</script>

<style lang="scss" scoped>
.login-form {
  @include phone-landscape(landscape) {
    display: block;
  }
  @include tablet-portrait {
    display: flex;
  }
  @include iphone-11(landscape) {
    display: block;
  }
  @include iphone-11-pro-max(landscape) {
    display: block;
  }
  @include iphone-12-pro-max(landscape) {
    display: block;
  }

  &__inner {
    transition: padding .2s ease-in-out;


    @include phone-landscape(landscape) {
      display: block;
      height: auto;
    }
    @include tablet-landscape {
      display: flex;
    }
    @include ipad-without-home-btn(landscape) {
      padding: 20px 0 35px 0;
    }
  }

  &__forgot {
    display: table;
    color: var(--gray-1);
    margin: 12px 0 0 auto;
    user-select: none;

    @include desktop-xs {
      &:hover {
        color: var(--white);
      }
    }

    &:active {
      color: var(--white);
    }
  }
  .form-view {
    &__submit-loading {
      border-top-color: var(--black);
    }
  }
}

.slide-enter-active {
  transition: all .8s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.slide-leave-active {
  transition: all .2s ease-in-out;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.deleted-enter-active,
.deleted-leave-active {
  transition: opacity .4s ease-in-out;

  :deep(.account-deleted__nest) {
    transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  }
}
.deleted-enter-from,
.deleted-leave-active {
  opacity: 0;
  transform: none;

  :deep(.account-deleted__nest) {
    opacity: 0;
    transform: scale(0.8);
  }
}
</style>
