import { createWebHistory, createRouter } from 'vue-router'
import LoginForm from './pages/LoginForm.vue'
import ContactForm from './pages/ContactForm.vue'
import LoginCheckEmail from './pages/LoginCheckEmail.vue'
import LoginNewUser from './pages/LoginNewUser.vue'
import LoginWithPassword from './pages/LoginWithPassword.vue'
import ForgotPasswordForm from './pages/ForgotPasswordForm.vue'
import ResetPasswordForm from './pages/ResetPasswordForm.vue'
import CheckYourEmail from './pages/CheckYourEmail.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginForm,
      meta: {
        transition: 'slide-right'
      }
    },
    {
      path: '/login/login-email-sent',
      name: 'login-check-email',
      component: LoginCheckEmail,
      meta: {
        transition: 'slide-left'
      },
      beforeEnter: (to, from) => {
        ['login-with-password', 'login-new-user'].includes(from.name)
          ? to.meta.transition = 'fade'
          : to.meta.transition = 'slide-left'
      }
    },
    {
      path: '/login/new-user',
      name: 'login-new-user',
      component: LoginNewUser,
      meta: {
        transition: ''
      },
      beforeEnter: (to, from) => {
        ['contact'].includes(from.name)
          ? to.meta.transition = 'fade'
          : to.meta.transition = 'slide-left'
      }
    },
    {
      path: '/login/login-with-password',
      name: 'login-with-password',
      component: LoginWithPassword,
      meta: {
        transition: 'fade'
      }
    },
    {
      path: '/login/contact',
      name: 'contact',
      component: ContactForm,
      meta: {
        transition: ''
      },
      beforeEnter: (to, from) => {
        ['login', 'login-check-email', 'check-your-email', 'login-new-user'].includes(from.name)
          ? to.meta.transition = 'slide-left'
          : to.meta.transition = 'fade'
      }
    },
    {
      path: '/login/reset-email-sent',
      name: 'check-your-email',
      component: CheckYourEmail,
      meta: {
        transition: ''
      },
      beforeEnter: (to, from) => {
        from.name === 'login'
          ? to.meta.transition = 'slide-left'
          : to.meta.transition = 'fade'
      }
    },
    {
      path: '/login/forgot-password',
      name: 'forgot-password',
      component: ForgotPasswordForm,
      meta: {
        transition: 'fade'
      }
    },
    {
      path: '/login/reset-password',
      name: 'reset-password',
      component: ResetPasswordForm,
      meta: {
        transition: 'fade'
      },
      beforeEnter: (to, from) => {
        from.name === 'login'
          ? to.meta.transition = 'slide-left'
          : to.meta.transition = 'fade'
      }
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
