<template>
  <div class="form-view login-form">
    <div class="form-view__content">
      <div class="form-view__nest">
        <div class="form-view__inner login-form__inner" :class="isFromBlog ? 'login-form__inner--new-user' : ''">
          <div class="form-view__top">
            <router-link :to="{ name: 'login' }" class="form-view__back">
              <font-awesome-icon :icon="['fa', 'chevron-left']" />
            </router-link>
            <logo></logo>
          </div>

          <h1 class="form-view__title">{{ $t('forms.pleaseEnterYourName') }}</h1>
          <p class="login-form__description" v-html="$t('auth.otherStudentsWillSee')"></p>
          <div class="form-view__form">
            <fieldset class="form-view__fieldset">
              <div class="form-view__input-nest" :class="{'form-view__input-nest--error': errors.firstName}">
                <input
                  v-model.trim="form.firstName"
                  type="text"
                  name="first-name"
                  placeholder=" "
                  id="first-name"
                  class="form-view__input"
                  @input="delete errors.firstName"
                  @keyup.enter="this.$refs.lastName.focus()"
                >
                <label for="first-name" class="form-view__placeholder">{{ errors.firstName ? errors.firstName[0] : $t('forms.yourFirstName') }}</label>
              </div>
            </fieldset>
            <fieldset class="form-view__fieldset">
              <div class="form-view__input-nest">
                <input
                  v-model.trim="form.lastName"
                  type="text"
                  name="last-name"
                  placeholder=" "
                  id="last-name"
                  ref="lastName"
                  class="form-view__input"
                  @keyup.enter="submitForm"
                >
                <label for="last-name" class="form-view__placeholder">{{ $t('forms.yourLastName') }}</label>
              </div>
            </fieldset>

            <button class="form-view__submit" :class="{'form-view__submit--disabled': !canSubmit}" @click="submitForm">
              <span class="form-view__submit-loading" v-if="submitLoading"></span>
              <template v-else>{{ $t('auth.letsGetStarted') }}</template>
            </button>
          </div>

          <div class="form-view__bottom">
            <p class="form-view__bottom-email">{{ $t('auth.using') }} <span class="form-view__bottom-link">{{form.email}}</span></p>
            <p class="form-view__bottom-wrong-user">{{ $t('auth.notYou') }} <span class="form-view__bottom-link"><router-link :to="{ name: 'login' }">{{ $t('auth.changeEmail') }}</router-link></span></p>
            <router-link :to="{ name: 'contact' }" @click="trackNavigationToContact">{{ $t('forms.needHelp') }}</router-link>
          </div>
        </div>
      </div>

      <language-switcher :floating="true" />
    </div>

    <sidebar :backgroundRandom="sidebarLeftImg"></sidebar>
  </div>
</template>

<script>
import Sidebar from '@login/components/layout/Sidebar.vue'
import Logo from '@login/components/layout/Logo.vue'
import LanguageSwitcher from '@shared/components/ui/LanguageSwitcher.vue'
import loginMixin from '@login/mixins/loginMixin'
import appClient from '@shared/api/appClient'


export default {
  name: 'LoginForm',
  components: {
    Sidebar,
    Logo,
    LanguageSwitcher
  },
  props: {
    sidebarLeftImg: {
      type: Number,
      required: true
    },
    customerPopupClosed: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      submitLoading: false
    }
  },
  computed: {
    isFromBlog () {
      return this.$route.query.from === 'blog'
    },
    canSubmit () {
      return this.form.firstName.length
    }
  },
  mixins: [loginMixin],
  methods: {
    submitForm() {
      this.validateForm()

      if (!this.noErrors || this.submitLoading) {
        return
      }

      const form = new FormData()

      form.append('email', this.form.email)
      form.append('first_name', this.form.firstName)
      if (this.form.lastName) form.append('last_name', this.form.lastName)

      this.trackEvent('button_clicked', { button: 'add_username_view_continue' })

      this.submitLoading = true

      appClient.loginUser(form).then((resp) => {
        if (resp.data?.email) {
          this.$router.push({ name: 'login-check-email' })
          this.trackEvent('signed_up_successfully')
        } else {
          window.location = '/'
          this.trackEvent('signed_in_successfully')
        }
      }).catch(({ response }) => {
        this.submitLoading = false
        this.$root.errors = response.data.errors ? response.data.errors : {}

        if (response) {
          if (response.status === 404) {
            this.$router.push({ name: 'login-new-user' })
          }
        }
      })
    },
    validateForm () {
      this.validateFirstName()
    },
    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
    },
    trackNavigationToContact () {
      this.trackEvent('button_clicked', { button: 'add_username_view_need_help' })
    }
  },
  mounted() {
    this.trackEvent('require_profile_setup')
  },
  unmounted () {
    this.clearErrors()
  },
  beforeRouteEnter (to, from, next) {
    if (from.name === 'login') {
      next()
    } else {
      window.location = '/login'
    }
  }
}
</script>

<style lang="scss" scoped>
.login-form {
  @include phone-landscape(landscape) {
    display: block;
  }
  @include tablet-portrait {
    display: flex;
  }
  @include iphone-11(landscape) {
    display: block;
  }
  @include iphone-11-pro-max(landscape) {
    display: block;
  }
  @include iphone-12-pro-max(landscape) {
    display: block;
  }

  &__inner {
    transition: padding .2s ease-in-out;

    @include phone-landscape(landscape) {
      display: block;
      height: auto;
    }
    @include tablet-landscape {
      display: flex;
    }
    @include ipad-without-home-btn(landscape) {
      padding: 20px 0 35px 0;
    }
  }

  &__description {
    :deep(br) {
      display: none;

      @include phone-landscape {
        display: block;
      }
    }
  }

  &__forgot {
    display: table;
    color: var(--gray-1);
    margin: 12px 0 0 auto;
    user-select: none;

    @include desktop-xs {
      &:hover {
        color: var(--white);
      }
    }

    &:active {
      color: var(--white);
    }
  }
  .form-view__submit-loading {
    border-top-color: var(--black);
  }
}

.form-view {
  &__back {
    top: 0
  }
  &__bottom {

    &-email, &-link  {
      color: var(--gray-3);
    }
    &-link {
      font-style: italic;
    }
    &-wrong-user {
      color: var(--gray-3);
      margin-bottom: 40px;
    }

  }
}
</style>
