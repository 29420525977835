<template>
  <div class="form-view">
    <div class="form-view__content">
      <div class="form-view__nest">
        <div class="form-view__inner">
          <div class="form-view__top">
            <router-link :to="{ name: 'login-with-password' }" class="form-view__back" v-if="!isMessage">
              <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </router-link>

            <logo></logo>
          </div>

          <div class="form-view__message" v-if="isExpired" v-html="$t('auth.passwordResetLinkExpired')"></div>

          <h1 class="form-view__title" :class="`form-view__title--${currentLanguage}`">{{ $t('auth.getANewPassword')}}</h1>
          <p class="form-view__description" v-html="$t('auth.enterEmailBelow')"></p>

          <div class="form-view__form">
            <fieldset class="form-view__fieldset">
              <div class="form-view__input-nest" :class="{'form-view__input-nest--error': errors.email && !isMessage}">
                <input
                  v-model.trim="form.email"
                  type="email"
                  name="email"
                  placeholder=" "
                  id="username"
                  class="form-view__input"
                  :class="{'form-view__input--error': errors.email && !isMessage}"
                  @input="delete errors.email"
                  @keyup.enter="submitForm"
                >
                <label for="username" class="form-view__placeholder" :class="{'form-view__placeholder--error': errors.email && !isMessage}">
                  <template v-if="errors.email && !isMessage">{{ errors.email[0] }}</template>
                  <template v-else>{{ $t('forms.enterYourEmail')}}</template>
                </label>
              </div>

              <span class="form-view__error-info" v-if="showErrorInfo">
                {{ $t('auth.makeSureSameEmail')}}
                <router-link :to="{name: 'contact'}">{{ $t('auth.clickToGetInTouch')}}</router-link>.
              </span>
            </fieldset>

            <button class="form-view__submit" :class="{'form-view__submit--disabled': !validEmail}" @click="submitForm">
              <span class="form-view__submit-loading" v-if="submitLoading"></span>
              <template v-else>{{ $t('auth.requestPasswordReset')}}</template>
            </button>
          </div>

          <div class="form-view__bottom">
            <router-link :to="{ name: 'contact' }" @click="trackNavigationToContact">{{ $t('forms.needHelp') }}</router-link>
          </div>
        </div>
      </div>

      <language-switcher :floating="true" />
    </div>

    <sidebar :backgroundRandom="sidebarRightImg"></sidebar>
  </div>
</template>

<script>
import Sidebar from '@login/components/layout/Sidebar.vue'
import Logo from '@login/components/layout/Logo.vue'
import LanguageSwitcher from '@shared/components/ui/LanguageSwitcher.vue'
import loginMixin from '@login/mixins/loginMixin'
import appClient from '@shared/api/appClient'

export default {
  name: 'ForgotPasswordForm',
  components: {
    Sidebar,
    Logo,
    LanguageSwitcher
  },
  props: {
    sidebarRightImg: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      submitLoading: false
    }
  },
  computed: {
    showErrorInfo () {
      return this.errors.email
    },
    isExpired () {
      return this.$route.query.expired
    }
  },
  mixins: [loginMixin],
  methods: {
    submitForm () {
      this.clearErrors()
      this.validateEmail()

      if (!this.noErrors || this.submitLoading) {
        return
      }

      const form = new FormData()

      form.append('email', this.form.email)

      this.trackEvent('button_clicked', { button: 'reset_password_view_reset' })

      this.submitLoading = true
      this.isThrottle = false

      appClient.forgotPassword(form).then(() => {
        this.$router.push({name: 'check-your-email'})
        this.submitLoading = false
      }).catch(({response}) => {
        this.$root.errors = response.data.errors ? response.data.errors : {}
        this.submitLoading = false
      })
    },
    trackNavigationToContact () {
      this.trackEvent('button_clicked', { button: 'reset_password_view_need_help' })
    }
  },
  beforeRouteLeave () {
    delete this.$route.query.expired
  },
  unmounted () {
    this.clearErrors()
  }
}
</script>
<style lang="scss" scoped>
.form-view {
  &__submit-loading {
    border-top-color: var(--black);
  }
}
</style>
