<template>
  <div class="account-deleted">
    <div class="account-deleted__nest">
      <div class="account-deleted__inner">
        <h3 class="account-deleted__title">{{ $t('deleteAccount.accountDeleted') }}</h3>
        <p class="account-deleted__text" v-html="$t('deleteAccount.deletedDescription')"></p>
      </div>

      <div class="account-deleted__ok" @click="$emit('close')">OK</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.account-deleted {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  padding: 0 24px;
  background: rgba(var(--gray-9-rgb), 0.9);
  backdrop-filter: blur(15px);
  opacity: 1;
  z-index: 200;

  @supports not (backdrop-filter: blur(15px)) {
    background: rgba(var(--gray-9-rgb), 0.95);
  }

  &__nest {
    text-align: center;
    border-radius: 16px;
    background: var(--gray-7);
    box-shadow: 2px 2px 28px 0px #000;
  }

  &__inner {
    padding: 16px;
  }

  &__title {
    font-size: $text-lg;
    color: var(--gray-1);
    margin: 0 0 8px 0;
  }

  &__text {
    font-size: $text-sm;
    color: var(--gray-1);
    margin: 0;
  }

  &__ok {
    font-size: $text-lg;
    line-height: 44px;
    font-weight: $semiBold;
    color: var(--navy);
    border-top: 1px solid rgba(var(--gray-4-rgb), 0.4);
    cursor: pointer;
  }
}
</style>