<template>
  <div class="form-view">
    <div class="form-view__content">
      <div class="form-view__nest">
        <div class="form-view__inner">
          <div class="form-view__top">
            <router-link :to="{ name: 'login' }" class="form-view__back">
              <font-awesome-icon :icon="['fa', 'chevron-left']" />
            </router-link>

            <logo></logo>
          </div>

          <h1 class="form-view__title">{{ $t('forms.checkYourEmail') }}</h1>
          <p class="form-view__description" :class="`form-view__description--${currentLanguage}`"><span v-html="$t('forms.confirmYourEmail')"></span> {{ form.email }}</p>
          <div class="form-view__bottom">
            <router-link :to="{ name: 'login-with-password' }" @click="trackNavigationToLoginWithPassword">{{ $t('auth.usePasswordToSignIn') }}</router-link>
          </div>
        </div>
      </div>

      <language-switcher :floating="true" />
    </div>

    <sidebar :backgroundRandom="sidebarRightImg"></sidebar>
  </div>
</template>

<script>
import Sidebar from '@login/components/layout/Sidebar.vue'
import Logo from '@login/components/layout/Logo.vue'
import LanguageSwitcher from '@shared/components/ui/LanguageSwitcher.vue'
import loginMixin from '@login/mixins/loginMixin'

export default {
  name: 'LoginStep2',
  components: {
    Sidebar,
    Logo,
    LanguageSwitcher
  },
  props: {
    sidebarRightImg: {
      type: Number,
      required: true
    }
  },
  mixins: [loginMixin],
  methods: {
    trackNavigationToLoginWithPassword () {
      this.trackEvent('button_clicked', { button: 'check_email_password_sign_in' })
    }
  },
  beforeRouteEnter (to, from, next) {
    // Redirect to the login screen if URL accessed directly
    if (['login', 'login-new-user', 'login-with-password'].includes(from.name)) {
      next()
    } else {
      window.location = '/login'
    }
  }
}
</script>

<style lang="scss" scoped>
.form-view {
  &__back {
    top: 0
  }

  &__bottom {
    position: absolute;
    bottom: 90px;
    width: 100%;

    @include phone-landscape {
      position: relative;
      bottom: auto;
    }
  }
}
.hide-mobile {
  display: none;
  @include phone-portrait {
    display: block;
  }
}
</style>
